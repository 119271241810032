export enum CustomerType {
  Professional = 'professional',
  Individual = 'individual',
}

export const ProfessionalCustomerGroupId = '06b4423e9d636aac1fb52f0207203bc6'

export const BrandsCategoryRootId = '018e4148dafb7081818bcb13eae7076f'

export const ExclusiveBrandsCategoryRootId = '018e4148a00f7591ae5dd739a6c1be94'
